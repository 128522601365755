import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Img from 'gatsby-image';
import styles from '../styles/layout.module.css';
import { Menu } from 'react-feather';

const Header = ({ siteTitle }) => {
	const [isExpanded, toggleExpansion] = useState(false);
	const data = useStaticQuery(graphql`
		query {
			sscsLogo: file(relativePath: { eq: "ieee-sscs-white.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<nav>
			<div id={styles.ieeeLinks}>
				<ul>
					<ol>
						<a href='https://www.ieee.org/'>IEEE.org</a>
					</ol>
					|
					<ol>
						<a href='https://ieeexplore.ieee.org/'>
							IEEE <em>Xplore</em> Digital Library
						</a>
					</ol>
					|
					<ol>
						<a href='https://standards.ieee.org/'>IEEE Standards</a>
					</ol>
					|
					<ol>
						<a href='https://spectrum.ieee.org/'>IEEE spectrum</a>
					</ol>
					|
					<ol>
						<a href='https://www.ieee.org/sitemap.html?WT.mc_id=mn_smap'>More Sites</a>
					</ol>
				</ul>
			</div>
			<div className='flex flex-wrap items-center justify-between bg-black-20 shadow-lg'>
				<Link to='/' className='flex-grow sscsLogo bg-bright-red-100 mr-4 w-auto inline-block bottom-right'>
					<Img className='m-6' fluid={data.sscsLogo.childImageSharp.fluid} alt={siteTitle} />
				</Link>
				<button
					className='block lg:hidden flex items-center px-3 py-2 mr-4'
					onClick={() => toggleExpansion(!isExpanded)}
				>
					<Menu />
				</button>

				<div className={`${isExpanded ? `block` : `hidden`} lg:block lg:flex lg:items-center w-full lg:w-auto`}>
					<div id={styles.menu}>
						<Link to='/courses' activeClassName='active'>
							Courses
						</Link>
						<Link to='/chipions' activeClassName='active'>
							Chipions
						</Link>
						<Link to='/articles' activeClassName='active'>
							Articles
						</Link>
						<Link to='/magazines' activeClassName='active'>
							Magazines
						</Link>
						<Link to='/twoHoursPerWeeks' activeClassName='active'>
							2h/Week
						</Link>
						<Link to='/gallery' activeClassName='active'>
							Gallery
						</Link>
						<Link to='/about' activeClassName='active'>
							About
						</Link>
					</div>
				</div>
			</div>
		</nav>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
