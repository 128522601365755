import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Facebook, Linkedin, AtSign, Gitlab } from 'react-feather';
import styles from '../styles/layout.module.css';
import Header from './header';

function Layout({ className = '', children, ...props }) {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<div className={`flex flex-col font-sans min-h-screen ${className}`} {...props}>
			<Header siteTitle={data.site.siteMetadata.title} />

			<main className='flex flex-col flex-1 md:justify-center max-w-5xl mx-auto px-4 py-8 md:p-8 w-full'>
				{children}
			</main>

			<footer className='bg-dark-red-100 top-left'>
				<div className='md:flex justify-between max-w-5xl mx-auto p-4 md:p-8 text-sm items-center'>
					<p className='text-white font-bold text-center'>
						&copy; IEEE SSCS Alexandria Student Chapter 2019&ndash;2020
					</p>
					<nav className='text-center' id={styles.footerIcons}>
						<a href='https://www.facebook.com/SSCSAlexSC/'>
							<Facebook />
						</a>
						<a href='https://www.linkedin.com/company/ieee-sscs/'>
							<Linkedin />
						</a>
						<a href='mailto:info@sscsalex.org'>
							<AtSign />
						</a>
						<a href='https://gitlab.com/sscsalex'>
							<Gitlab />
						</a>
					</nav>
				</div>
			</footer>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
